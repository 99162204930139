import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';


const Footer = () => {
  return (
    <FooterWrapper>
    	<FooterContainer>
	      <Row>
	        <Column>
	              <p className="footer-title">Pages</p>
	              <ul>
	                <li><Link to="/">Map</Link></li>
	                <li><Link to="/about">About</Link></li>
	                <li><Link to="/recommend">Recommend</Link></li>
                  <li><Link to="/premium-listing" activeClassName="active">Premium listing</Link></li>
	              </ul>
	          </Column>
	          <AboutColumn>
	      		<p className="footer-title">About</p>
	      		<p>Best in Brighton, the best places in and around the City of Brighton UK, collated and rated by locals.</p>
	      	  </AboutColumn>
	          <Column>
	              <p className="footer-title">Connect</p>
	              <ul className="header-links">
			          <li>
			            <a href="https://www.facebook.com/bestinbrighton" target="_blank" rel="noreferrer">
			              <span className="visually-hide">View on Facebook</span>
			              <FaFacebook />
			            </a>
			          </li>
			          <li>
			            <a href="https://twitter.com/bestbrightonuk" target="_blank" rel="noreferrer">
			              <span className="visually-hide">View on Twitter</span>
			              <FaTwitter />
			            </a>
			          </li>
			           <li>
			            <a href="https://www.instagram.com/bestinbrighton/" target="_blank" rel="noreferrer">
			              <span className="visually-hide">View on Instagram</span>
			              <FaInstagram />
			            </a>
			          </li>
			        </ul>
	          </Column>
	      </Row> 
	      
	    </FooterContainer>  
	     <p className="copyright-info">
		  	&copy; { new Date().getFullYear() }, Best in Brighton <br/>
        Photography by <a href="https://www.tomsnaps.com" target="_blank" rel="noreferrer">Tomsnaps</a> 
		  </p>
    </FooterWrapper>
  );
};

export default Footer;

const FooterContainer = styled.div`
	max-width: 1400px;
	margin: 0 auto;
	padding: 40px 0;
`

const Row = styled.div`
	flex-direction: row;
	display: flex;
	flex-wrap: wrap;
`

const Column = styled.div`
   padding: 0 40px;
`

const AboutColumn = styled(Column)`
	flex-basis: 100%;
    line-height: 25px;

    @media(min-width: 765px) {
    	flex-basis: 30%;
    }
`

const FooterWrapper = styled.footer`
  width: 100%;
  line-height: 2em;
  background-color: #1c2429;

  p {
    font-size: .9em;
    margin: 0;
    color: #fff;
  }

  .footer-title {
    margin-top: 14px;
    font-size: 20px;
    font-weight: bold;
  }

   ul {
    list-style: none;
    margin: 0;
    padding:0;
    letter-spacing: 0.25px;

    &.social-links {
          display: flex;

          a {
            font-size: 22px;
            background-color: #3c7db6;
            margin-right: 10px;
            padding: 5px 10px;
            border-radius: 50%;

            &:hover {
              color: #092536;
            }
          }
        }

    li {
      margin: 0;
      a {
        font-size: 16px;
        color: #fff;
        text-decoration: none;

        &:hover {
        	color: #ff9900;
        }
      }
    }
  }

  .copyright-info {
  	text-align: center;
  	padding: 10px 0;
    border-top: 1px solid #ddd;
    margin-top: 20px;
    line-height: 1.5em;

    a {
      color: #fff;
    }
  }
`