import React from 'react';
import { Link } from 'gatsby';
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';

import Container from 'components/Container';
import Logo from 'components/Logo';

const Header = () => {
  return (
    <header className="header">
      <Container>
        <p className="header-logo">
          <Link to="/">
            <Logo />
          </Link>
        </p>

        <ul className="header-menu">
          <li>
            <Link to="/" activeClassName="active">Home</Link>
          </li>
          <li>
            <Link to="/about" activeClassName="active">About</Link>
          </li>
          <li>
            <Link to="/articles" activeClassName="active">Articles</Link>
          </li>
           <li>
            <Link to="/recommend" activeClassName="active">Recommend</Link>
          </li>
        </ul>

        <ul className="header-links">
          <li>
            <a href="https://www.facebook.com/bestinbrighton" target="_blank" rel="noreferrer">
              <span className="visually-hide">View on Facebook</span>
              <FaFacebook />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/bestbrightonuk" target="_blank" rel="noreferrer">
              <span className="visually-hide">View on Twitter</span>
              <FaTwitter />
            </a>
          </li>
           <li>
            <a href="https://www.instagram.com/bestinbrighton/" target="_blank" rel="noreferrer">
              <span className="visually-hide">View on Instagram</span>
              <FaInstagram />
            </a>
          </li>
        </ul>
      </Container>
    </header>
  );
};

export default Header;
