import React from 'react';
import logo from 'assets/images/best-in-brighton-landscape.png';

const Header = () => {
  return (
    <span className="logo">
      <img src={logo} alt="Best in Brighton" />
    </span>
  );
};

export default Header;
